<template>
  <div>
    <div class="bj" @click="delClick">
      <div class="bjLeftCont" @click.stop="stop">
        <div class="headBox">
          <div class="name">物流信息</div>
          <div class="iconfont icon-close close" @click="delClick"></div>
        </div>
        <div class="pjList scroll"></div>
      </div>
    </div>
    <ImgBig @del="imgDelClick" v-if="imgShow" />
  </div>
</template>

<script>
import ImgBig from "../component/imgBig.vue";

export default {
  components: {
    ImgBig,
  },
  name: "",
  data() {
    return {
      imgShow: false,
    };
  },
  created() {},
  methods: {
    stop() {},
    delClick() {
      this.$emit("del", { show: false });
    },
    imgClick() {
      this.imgShow = true;
    },
    imgDelClick() {
      this.imgShow = false;
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.bjLeftCont {
  padding: 25px 5px 25px 25px;
  box-sizing: border-box;
}
.headBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  .name {
    font-weight: 600;
    font-size: 20px;
  }
  .close {
    font-weight: 600;
    font-size: 20px;
    color: #333;
    cursor: pointer;
  }
}
.pjList {
  box-sizing: border-box;
  padding-right: 20px;
  overflow: auto;
  height: calc(100% - 40px);
  .pjLi {
    display: flex;
    margin-bottom: 15px;
    .img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
    .pjLiBox {
      width: calc(100% - 60px);
      margin-left: 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #ebebeb;
      .pjHead {
        height: 45px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .name {
          color: #11192d;
        }
        .txt {
          color: #7c889c;
        }
      }
      .pjTxt {
        margin-bottom: 10px;
        margin-top: 5px;
      }
      .pjImgs {
        overflow: hidden;
        .pjimg {
          float: left;
          width: calc(20% - 8px);
          margin-right: 10px;
          margin-bottom: 10px;
          padding-top: calc(20% - 8px);
          position: relative;
          cursor: pointer;
          img {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
          }
        }
        .pjimg:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
