<template>
  <div>
    <div class="bj">
      <div class="bjContent">
        <div class="bjCont">
          <div class="iconfont icon-close cha" @click="delClick"></div>
          <div class="cont">
            <div class="contHead">退款</div>
            <div class="contBox">
              <a-textarea v-model="value" style="margin-top: 15px;" placeholder="退款原因~"
                :auto-size="{ minRows: 3, maxRows: 8 }" />
              <div class="upImg">
                <a-upload name="file" :multiple="true" action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  :headers="headers" @change="handleChange">
                  <a-button style="font-size: 12px;"> <a-icon type="upload" />上传图片</a-button>
                  <span class="text">1/6</span><span class="txt">(最多上传6张)</span>
                </a-upload>
                <div class="imgs">
                  <div class="img">
                    <img
                      src="https://img2.baidu.com/it/u=2427333929,299356392&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1719162000&t=85a98d24579c1168d4d00770d433871c">
                    <div class="iconfont icon-close close"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gnBox">
            <div class="btn qx" @click="delClick">取消</div>
            <div class="btn">退款</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Form, Input, Button, Upload, Icon } from 'ant-design-vue';
export default {
  components: {
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-form': Form,
    'a-form-item': Form.Item,
    'a-input': Input,
    'a-button': Button,
    'a-textarea': Input.TextArea,
    'a-upload': Upload,
    'a-icon': Icon,
  },
  data() {
    return {
      size: 'default',
      value: '',
      headers: {
        authorization: 'authorization-text',
      },
      form: {
        username: '',
        password: '',
      },
    };
  },
  mounted() { },
  activated() { },
  methods: {
    delClick() {
      this.$emit("show", { title: '' });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    popupScroll() {
      console.log('popupScroll');
    },
  },
};
</script>
<style lang='less' scoped>
.bjContent {
  display: flex;
  width: 100%;
  justify-content: center;

  .bjCont {
    background-color: #fff;
    width: 600px;
    margin-top: 90px;
    border-radius: 10px;
    position: relative;

    .cha {
      position: absolute;
      cursor: pointer;
      top: 10px;
      font-weight: 600;
      right: 20px;
      color: #9c9c9c;
    }

    .cont {
      padding: 20px 20px 10px;

      .contHead {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }

      .contBox {
        padding-top: 0px;

        .name {
          margin-bottom: 5px;

          span {
            color: #f30000;
          }
        }

        .tishi {
          line-height: 150%;
          color: #bfbfbf;
          font-size: 12px;
          margin-top: 5px;
          text-align: justify;
        }

        .upImg {
          margin-top: 10px;

          .text {
            margin-left: 20px;
            font-size: 12px;
          }

          .txt {
            margin-left: 5px;
            font-size: 12px;
            color: #999;
          }

          .imgs {
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;

            .img {
              width: calc(20% - 12px);
              margin-right: 15px;
              padding-top: calc(20% - 12px);
              position: relative;
              margin-bottom: 15px;
              overflow: unset;

              img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
              }

              .close {
                position: absolute;
                top: -3px;
                right: -3px;
                background-color: #b3b3b3;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: #fff;
              }
            }

            .img:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }

    }

    .gnBox {
      border-top: 1px solid #d9d9d9;
      padding: 10px 24px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .btn {
        color: #fff;
        background: #e23;
        border: 1px solid #e23;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 15px;
        cursor: pointer;
      }

      .qx {
        background: unset;
        border: 1px solid rgb(180, 180, 180);
        color: #666;
      }
    }
  }
}
</style>